import Dexie from 'dexie';

export class DBDenios extends Dexie {
    constructor() {
        super('DBDenios');
        this.version(1).stores(
            {
                navigations: '_uid, link, group, caption, position, component, is_app_route',
                contents: '_uid, content, full_slug'
            }
        );
        this.open();
    }
    
    // example: http://www.google.de/ -> http://www.google.de
    deleteTrailingSlash = (url) => {
        return url.replace(/\/$/, '');
    }

    getContentByRoute = async (route) => {
        const routeWithoutTrailingSlash = this.deleteTrailingSlash(route);
        const contents = await db.contents.filter((content) => {
            return this.deleteTrailingSlash(content.full_slug).endsWith(routeWithoutTrailingSlash)
        }).toArray()

        if (contents.length > 0) return contents[0]
        return null
    }

    clearContents = async () => {
        await db.contents.clear()
        await db.navigations.clear()
    }
}

export const db = new DBDenios();
