import React from "react";
import NavBar from "../NavBar/NavBar";
import { withAuth0 } from "@auth0/auth0-react";
import FirebaseManager from "../FirebaseManager";
import {
	CONSULTATION_URL,
} from "../data/Constants";
import Modal from "../Reusable Views/Modal/Modal";
import OfferForm from "./OfferForm";
import MatrixInfoView from "../MatrixView/MatrixInfoView";
import StoryblokRenderer from "../Reusable Views/StoryblokRenderer";
import { withRouter } from "react-router-dom";
import { db } from "../data/db";
import { withLocalizationHook } from "src/Localization";

class DetailsForm extends React.Component {
	constructor(props) {
		super(props);

		this.showLGK = this.showLGK.bind(this);

		this.state = {
			showOfferForm: false,
			toggleMatrixHelp: props.toggleMatrixHelp,
			storyContent: null,
		};
	}

	async load() {
		const result = await db.getContentByRoute("/contents/h-sentence-details");
		this.setState({
			storyContent: result.content.contents,
			isLoading: false
		});
		/*
		axios
			.get(
				STORYBLOK_BASE_URL +
					"apps/hazmat-storage/de_de/contents/h-sentence-details" +
					"?version=published&token=" +
					STORYBLOK_TOKEN +
					"&cv=" +
					Date.now()
			)
			.then((result) => {
				const content = result.data.story.content.contents;

				this.setState({ story_content: content, isLoading: false });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ renderErrorURL: "-", isLoading: false });
				}
			});*/
	}

	componentDidMount() {
		this.load();
	}

	showConsultationPage() {
		FirebaseManager.logEvent(FirebaseManager.CONSULTATION);
		window.open(CONSULTATION_URL, "_blank");
	}

	toggleOfferForm() {
		this.setState({ showOfferForm: !this.state.showOfferForm });
	}

	Form() {
		const {
			showOfferForm,
			toggleMatrixHelp,
			storyContent,
		} = this.state;
		const { t } = this.props.localizationHook;

		return (
			<div className="paddedContent">
				<MatrixInfoView showMatrixHelp={toggleMatrixHelp} />

				<div>
					<button onClick={() => this.showConsultationPage()}>
						{t.details.consult}
					</button>
					<button className="outline" onClick={() => this.toggleOfferForm()}>
						{t.details.products}
					</button>
				</div>

				{storyContent && (
					<StoryblokRenderer content={storyContent} />
				)}

				{showOfferForm && (
					<Modal>
						<OfferForm
							showCancelButton={true}
							cancelHandler={() => this.toggleOfferForm()}
						/>
					</Modal>
				)}
			</div>
		);
	}

	showLGK() {
		this.props.history.push({
			pathname: "/info/viewer",
			state: { storyblok_url: "hazard_classes" },
		});
	}

	render() {
		const { t } = this.props.localizationHook;

		return (
			<div className="content">
				<NavBar
					title={t.details.title}
					leftBarButtonItemTitle={t.details.legend}
					leftBarButtonItemAction={this.showLGK}
					rightBarButtonItemTitle={t.general.done}
					rightBarButtonItemAction={this.props.cancelHandler}
				/>

				{this.Form()}
			</div>
		);
	}
}

export default withAuth0(withRouter(withLocalizationHook(DetailsForm)));
