import React from "react";
import NavBar from "./NavBar/NavBar";
import TableViewCell from "./TableView/TableViewCell";
import TableViewHeader from "./TableView/TableViewHeader";
import { HIDE_LANGUAGE_SELECT } from "./data/Constants";
import { useEffect, useState } from "react";
import { useLocalization } from "./Localization";
import Cookies from "js-cookie";

const LaunchView = ({ history }) => {
	const { t, country, language, firstStart, localizationIsSet } = useLocalization()
	const [isTutorialPresented] = useState(Cookies.get("isTutorialPresented"));
	
	useEffect(() => {
		if (HIDE_LANGUAGE_SELECT || !firstStart) {
			history.push("/matrix");
		}
	}, [history, firstStart])

	const next = () => {
		localizationIsSet()
		isTutorialPresented ? history.push("/matrix") : history.push("/intro");
	}

	const openLanguageSelector = (isCountry) => {
		history.push("/launch/pickLanguage?select=" + (isCountry ? "c" : "l"));
	}

	return (
		<div>
			<NavBar
				rightBarButtonItemTitle={t.general.next}
				rightBarButtonItemAction={next}
			/>

			<div className="launchLogo" />

			<div className="launchGradient">
				<p className="launchTitle">{t.general.app_name}</p>

				<div
					style={{
						position: "absolute",
						backgroundColor: "white",
						marginTop: "100px",
						width: "100%",
						paddingBottom: "30px",
					}}
				>
					<TableViewHeader title={t.language_picker.country_2} />
					<TableViewCell
						label={t.language_picker['country_' + country]}
						onClick={() => openLanguageSelector(true)}
						/>

					<TableViewHeader title={t.language_picker.language} />
					<TableViewCell
						label={t.language_picker['language_' + language]}
						onClick={() => openLanguageSelector(false)}
					/>
				</div>
			</div>
		</div>
	);	
}

export default LaunchView;
