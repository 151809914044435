export const storageStates = {
  STATE_NONE: "0",
  STATE_GREEN: "1",
  STATE_YELLOW: "2",
  STATE_RED: "3",
};

//const STATE_NONE = storageStates.STATE_NONE;
const STATE_GREEN = storageStates.STATE_GREEN;
const STATE_YELLOW = storageStates.STATE_YELLOW;
const STATE_RED = storageStates.STATE_RED;

export const storageClasses = [
  {
    classId: "1",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
      },
      {
        classId: "11/13",
        status: STATE_RED,
      },
      {
        classId: "10/12",
        status: STATE_RED,
      },
      {
        classId: "8",
        status: STATE_RED,
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
      },
      {
        classId: "6.1",
        status: STATE_RED,
      },
      {
        classId: "5",
        status: STATE_RED,
      },
      {
        classId: "4.3",
        status: STATE_RED,
      },
      {
        classId: "4.2",
        status: STATE_RED,
      },
      {
        classId: "4.1",
        status: STATE_RED,
      },
      {
        classId: "3",
        status: STATE_RED,
      },
      {
        classId: "2",
        status: STATE_RED,
      },
      {
        classId: "1",
        status: STATE_GREEN,
      },
    ],
  },
  {
    classId: "2",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "8",
        status: STATE_RED,
        remarks: "6",
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: "6",
      },
      {
        classId: "6.1",
        status: STATE_RED,
        remarks: "6",
      },
      {
        classId: "5",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "2",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "3",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "8",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "6.1",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "5",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.3",
        status: STATE_RED,
      },
      {
        classId: "4.2",
        status: STATE_RED,
      },
      {
        classId: "4.1",
        status: STATE_RED,
      },
      {
        classId: "3",
        status: STATE_GREEN,
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "4.1",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "8",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "6.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "5",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.3",
        status: STATE_RED,
      },
      {
        classId: "4.2",
        status: STATE_RED,
      },
      {
        classId: "4.1",
        status: STATE_GREEN,
      },
      {
        classId: "3",
        status: STATE_RED,
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "4.2",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "11/13",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "10/12",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "8",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "6.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "5",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.3",
        status: STATE_RED,
      },
      {
        classId: "4.2",
        status: STATE_GREEN,
      },
      {
        classId: "4.1",
        status: STATE_RED,
      },
      {
        classId: "3",
        status: STATE_RED,
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "4.3",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "11/13",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "10/12",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "8",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "6.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "5",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.3",
        status: STATE_GREEN,
      },
      {
        classId: "4.2",
        status: STATE_RED,
      },
      {
        classId: "4.1",
        status: STATE_RED,
      },
      {
        classId: "3",
        status: STATE_RED,
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "5",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "8",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "6.1",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "5",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "6.1",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "8",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
      },
      {
        classId: "6.1",
        status: STATE_GREEN,
      },
      {
        classId: "5",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "6",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "6.2",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
      },
      {
        classId: "11/13",
        status: STATE_RED,
      },
      {
        classId: "10/12",
        status: STATE_RED,
      },
      {
        classId: "8",
        status: STATE_RED,
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_GREEN,
      },
      {
        classId: "6.1",
        status: STATE_RED,
      },
      {
        classId: "5",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "6",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "7",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
      },
      {
        classId: "11/13",
        status: STATE_RED,
      },
      {
        classId: "10/12",
        status: STATE_RED,
      },
      {
        classId: "8",
        status: STATE_RED,
      },
      {
        classId: "7",
        status: STATE_GREEN,
      },
      {
        classId: "6.2",
        status: STATE_RED,
      },
      {
        classId: "6.1",
        status: STATE_RED,
      },
      {
        classId: "5",
        status: STATE_RED,
      },
      {
        classId: "4.3",
        status: STATE_RED,
      },
      {
        classId: "4.2",
        status: STATE_RED,
      },
      {
        classId: "4.1",
        status: STATE_RED,
      },
      {
        classId: "3",
        status: STATE_RED,
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "8",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_RED,
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "8",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
      },
      {
        classId: "6.1",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "5",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "2",
        status: STATE_RED,
        remarks: "6",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "10/12",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "10/12",
        status: STATE_GREEN,
      },
      {
        classId: "8",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
      },
      {
        classId: "6.1",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "5",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "2",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "11/13",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "11/13",
        status: STATE_GREEN,
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "8",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
      },
      {
        classId: "6.1",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "5",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "2",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
  {
    classId: "NG",
    status: STATE_GREEN,
    selected: "false",
    combinations: [
      {
        classId: "NG",
        status: STATE_GREEN,
      },
      {
        classId: "11/13",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "10/12",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "8",
        status: STATE_RED,
      },
      {
        classId: "7",
        status: STATE_RED,
      },
      {
        classId: "6.2",
        status: STATE_RED,
      },
      {
        classId: "6.1",
        status: STATE_RED,
      },
      {
        classId: "5",
        status: STATE_RED,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "4.1",
        status: STATE_RED,
        remarks: "3",
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "2",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "1",
        status: STATE_RED,
      },
    ],
  },
];
