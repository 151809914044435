/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext, createContext } from "react";
import {
  localizations,
  fallbackLocalization,
  getSupportedAttribute,
} from "src/config";
import LocalizedStrings from "react-localization";
import GenerateRoutes from "src/data/GenerateRoutes";
import { usePersistedState } from "src/utils";

export const LocalizationContext = createContext();

export const LocalizationProvider = ({ children }) => {
  const [localization, setLocalization] = usePersistedState(
    "@localization",
    fallbackLocalization
  );
  const [firstStart, setFirstStart] = usePersistedState("@firstStart", true);

  const languages = getSupportedAttribute("file");
  let strings = new LocalizedStrings(languages, { logsEnabled: false });
  const [t, setT] = useState(strings);
  const [language, country] = localization.split("_");

  // get localized boxesPerRow
  const { boxesPerRow } = localizations.find(
    ({ country: currentCountry }) => currentCountry === country
  );
  
  useEffect(() => {
    strings.setLanguage(localization);
    setT(strings);
    GenerateRoutes.parseNavigation(localization);
    GenerateRoutes.downloadContent(localization);
  }, [localization]);

  // check wether country is valid
  const setCountry = (country) => {
    try {
      const { languages } = localizations.find(
        ({ country: currentCountry }) => currentCountry === country
      );
      setLocalization(`${languages[0].language}_${country}`);
    } catch (e) {
      setLocalization(fallbackLocalization);
    }
  };

  // check wether language in combination with the country is valid
  const setLanguage = (lang, newCountry = country) => {
    try {
      const { languages } = localizations.find(
        ({ country: currentCountry }) => currentCountry === newCountry
      );
      const { language } = languages.find(
        ({ language: currentLanguage }) => currentLanguage === lang
      );
      setLocalization(`${language}_${newCountry}`);
    } catch (e) {
      setLocalization(fallbackLocalization);
    }
  };

  const localizationIsSet = () => {
    setFirstStart(false);
  };

  const countryLanguageString = "§" + country.toUpperCase() + "/" + language;

  const context = {
    t,
    localization,
    language,
    country,
    countryLanguageString,
    firstStart,
    setCountry,
    setLanguage,
    localizationIsSet,
    boxesPerRow
  };

  return (
    <LocalizationContext.Provider value={context}>
      {children}
    </LocalizationContext.Provider>
  );
};

export const useLocalization = () => useContext(LocalizationContext);
