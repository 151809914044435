/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";

export async function load(key) {
  try {
    const almostThere = localStorage.getItem(key);
    if (!almostThere) {
      return undefined;
    }
    return JSON.parse(almostThere);
  } catch {
    return null;
  }
}

export async function save(key, value) {
  try {
    localStorage.setItem(key, JSON.stringify(value));
    return true;
  } catch {
    return false;
  }
}

export async function remove(key) {
  try {
    localStorage.removeItem(key);
  } catch {
    /* continue regardless of error */
  }
}

export async function clear() {
  try {
    localStorage.clear();
  } catch {
    /* continue regardless of error */
  }
}

/**
 * Persist a state value in the storage
 * It is important that you call useRehydrate before usePersist.
 * Otherwise the default state will persist itself before the persisted state could be rehydrated.
 */
export const usePersist = (key, value) => {
  React.useEffect(() => {
    save(key, value);
  }, [value]);
};

/**
 * Rehydrate storage value to state
 */
export const useRehydrate = (key, updateFn) =>
  React.useEffect(() => {
    const rehydrate = async () => {
      const value = await load(key);
      if (value !== undefined) updateFn(value);
    };
    rehydrate();
  }, []);

export function usePersistedState(key, defaultState) {
  const [state, setState] = React.useState(defaultState);

  useRehydrate(key, setState);
  usePersist(key, state);

  return [state, setState];
}
