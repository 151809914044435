import MatrixWarningsView from "../../MatrixWarningsView";
import { useMatrix } from "src/useMatrix";
import { useLocalization } from "src/Localization";

export const MatrixInformationContent = ({
  allRemarks,
  status,
  classesStr,
  classesCombined,
}) => {
  const { storageStates } = useMatrix();
  const { t } = useLocalization();
  let title = {
    intro: "",
    classes: "",
    outro: "",
  };
  let titleAdditionComponent;

  if (status === storageStates.STATE_GREEN) {
    title = {
      intro: t.matrix.matrix_summary_intro,
      classes: classesStr,
      outro: t.formatString(t.matrix.matrix_summary_green),
    };
  } else if (status === storageStates.STATE_YELLOW) {
    title = {
      intro: classesCombined
        ? t.matrix.matrix_summary_intro
        : t.matrix.matrix_summary_intro_self,
      classes: classesStr,
      outro: t.formatString(t.matrix.matrix_summary_yellow),
    };
    titleAdditionComponent = (
      <div>
        <p>
          <span style={{ textDecoration: "underline", display: "block" }}>
            {t.matrix.matrix_summary_description}
          </span>
          <span>{t.matrix.matrix_summary_yellow_outro}</span>
        </p>
        <p>
          <span>
            {t.formatString(t.matrix.matrix_outro, {
              underline: (
                <span style={{ textDecoration: "underline" }}>
                  {t.matrix.matrix_outro2}
                </span>
              ),
            })}
          </span>
        </p>
      </div>
    );
  } else if (status === storageStates.STATE_RED) {
    title = {
      intro: t.matrix.matrix_summary_intro,
      classes: classesStr,
      outro: t.formatString(t.matrix.matrix_summary_red),
    };
  }
  return (
    <div>
      <p className="text" style={{ fontWeight: "bold" }}>
        <span style={{ display: "block" }}>{title?.intro}</span>
        <span style={{ display: "block" }}>{title?.classes}</span>
        {title?.outro}
      </p>
      {titleAdditionComponent}
      <MatrixWarningsView remarks={allRemarks} />
    </div>
  );
};
