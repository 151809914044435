import React, { useState } from 'react';
import { Swiper, SwiperSlide } from "swiper/react"
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import 'swiper/components/navigation/navigation.min.css'
import 'swiper/components/pagination/pagination.min.css'
import './intro.css';
import NavBar from "../NavBar/NavBar";
import SwiperCore, {
    Keyboard, Pagination, Navigation, Mousewheel, Virtual
} from 'swiper';
import Cookies from "js-cookie";
import { useLocalization } from "src/Localization";
import { getSupportedAttribute } from 'src/config';

SwiperCore.use([Keyboard, Pagination, Navigation, Mousewheel]);

function Intro({ ...props }) {
    const { localization } = useLocalization();
    const { Slides } = getSupportedAttribute('slides')[localization];

	const { t } = useLocalization();

    const [swiper, setSwiper] = React.useState(null);
    const [lastSlide] = useState(Slides.length - 1);
    const [currentSlide, setCurrentSlide] = useState();
    const isLastSlide = lastSlide === currentSlide ? true : false;
    
    const slides = Slides.map(
        (el) =>
            <div className="contentIntro">
                <div className="imageContainer">
                    <img className="image" src={el.image} alt={el.title} />
                </div>
                <div className="textContainer">
                    <div className="title">{el.title}</div><div className="introText">{el.text}</div>
                </div>
            </div>
    );

    const skipTutorial = () => {
        if (swiper) {
            swiper.slideTo(lastSlide);
        }
    };

    const nextSlide = () => {
        if (swiper) {
            swiper.slideNext(300, false)
        }
    };

    const doneTutorial = () => {
        if (Cookies.get("isTutorialPresented")) {
            props.history.push("/info");
        } else {
            Cookies.set("isTutorialPresented", true);
            props.history.push("/matrix");
        }
    };

    return (
        <div className="tutorialOuterContent">
            <div className="tutorialContent">
                <NavBar
                    title={t.intro.title}
                />
                <div className="tutorialInnerContent">
                    <Swiper modules={[Virtual]}
                        slidesPerView={1}
                        spaceBetween={30}
                        mousewheel={true}
                        keyboard={{ "enabled": true }}
                        pagination={{ "clickable": true }}
                        className="tutorial"
                        onSlideChange={(index) => { setCurrentSlide(index.snapIndex) }}
                        onSwiper={(s) => {
                            setSwiper(s);
                        }}
                    >
                        {slides.map((slideContent, index) => (
                            <SwiperSlide key={index} virtualIndex={index}>
                                <div>{slideContent}</div>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                    </div>
                <div className="navigation">
                    <div className="buttonContainer"><button style={!isLastSlide ? { backgroundColor: 'transparent' } : { backgroundColor: 'transparent', visibility:'hidden' }} onClick={skipTutorial}>{t.intro.skip}</button></div> 
                    <div className="buttonContainer"><button onClick={isLastSlide ? doneTutorial : nextSlide}>{currentSlide === lastSlide ? t.intro.done : t.intro.next}</button></div>
                </div>            
            </div>
        </div>
    );
}

export default Intro;
