import "./TableView.css";
import PropTypes from "prop-types";

function TableViewCell({ label, onClick, disabled, detail, isLink, isLabel }) {
	return (
		<div
			className="tableViewCell"
			onClick={onClick}
			id={disabled ? "disabled" : null}
			style={isLabel ? {color: "gray"} : null}
		>
			{label}

			<div className="tableViewCell-right">
				<p className="tableViewCell-detail">{detail}</p>
				{isLink ? 
					<div className="tableViewCell-web" />:
					isLabel ? null :
						<div className="tableViewCell-chevron" />
				}
			</div>
		</div>
	);
}

TableViewCell.defaultProps = {
	label: "Default Cell Label",
	detail: "",
	isLink: false,
	isLabel: false,

};

TableViewCell.propTypes = {
	label: PropTypes.string.isRequired, // value for the cell label.
	action: PropTypes.func, // (optional) function that should be called when tapping or clicking the cell.
	showChevron: PropTypes.bool, // show chevron on the right side of the cell. Default is true.
	showCheckmark: PropTypes.bool, // show a checkmark on the right side of the cell. Default is false.
	disabled: PropTypes.bool, // when disabled, the cell does not have a hover effect. Actions can still be called.
};

export default TableViewCell;
