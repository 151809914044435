import "./TableView.css";
import PropTypes from "prop-types";

function TableViewTextCell(props) {
	return (
		<div className="tableViewCell" id="text">
			{props.label}
			<input
				type={props.secureText ? "password" : "text"}
				placeholder={
					props.placeholder === undefined ? props.label : props.placeholder
				}
				value={props.text}
				name={props.name}
				onChange={props.onChange}
			/>

			{props.showSecureEntryToggle && (
				<div
					className="tableViewCell-secureEntryToggle"
					id={props.secureText ? "on" : "off"}
					onClick={props.toggleSecureEntry}
				/>
			)}
		</div>
	);
}

TableViewTextCell.defaultProps = {
	label: "Default Cell Label",
	text: "",
	showSecureEntryToggle: false,
};

TableViewTextCell.propTypes = {
	label: PropTypes.string.isRequired, // value for the cell label.
	name: PropTypes.string.isRequired, // name of the field to handle changes.
	onChange: PropTypes.func.isRequired, // function to call when the text changes.
	text: PropTypes.string, // the text field content
	showSecureEntryToggle: PropTypes.bool, // when true, a button to toggle between clear/secure text is displayed. Default is false.
	secureText: PropTypes.bool, // when true, the text in the field is secure.
};

export default TableViewTextCell;
