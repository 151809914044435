import { MAX_FREE_USAGE } from "../data/Constants";
import Cookies from "js-cookie";
import { useAuth0 } from "@auth0/auth0-react";
import ModalBox from "../Reusable Views/ModalBox/ModalBox";
import FirebaseManager from "../FirebaseManager";
import { useLocalization } from "src/Localization";

const UsageCountReminderBox = (props) => {
	const { loginWithRedirect } = useAuth0();
	const { t, language } = useLocalization()

	const remainingFreeUsageCount = () => {
		// Returns the max free usage (as defined in Constants.js) minus the already used count.
		// If zero, the user can't use the app without an account any more.

		let usedCount = Cookies.get("AppUsageCount") ?? 0;

		if (usedCount >= MAX_FREE_USAGE) {
			return 0;
		}

		return MAX_FREE_USAGE - usedCount;
	}

	const closeBox = () => {
		let usedCount = Cookies.get("AppUsageCount") ?? 0;

		FirebaseManager.logEvent(FirebaseManager.USER_TRIAL, {
			versuch: MAX_FREE_USAGE - (MAX_FREE_USAGE - usedCount),
		});

		props.closeFunction();
	}

	const ModalContent = () => {
		return (
			<div>
				<p className="modalBox-text">{t.usage_reminder.text}</p>

				<button
					onClick={() =>
						loginWithRedirect({
							language,
						})
					}
				>
					Login
				</button>

				<button onClick={closeBox} disabled={remainingFreeUsageCount() <= 0}>
					{t.formatString(t.usage_reminder.button, {
						count: remainingFreeUsageCount(),
					})}
				</button>
			</div>
		);
	}

	return (
		<ModalBox
			title={t.usage_reminder.title}
			closeFunction={closeBox}
		>
			{ModalContent()}
		</ModalBox>
	);
}

export default UsageCountReminderBox;
