/* eslint-disable array-callback-return */
export const fallbackLocalization = 'de_de';

export const localizations = [
  {
    country: 'de',
    languages: [
      {
        language: 'de',
        file: require('../assets/languages/de_de.json'),
        matrix: require('../assets/matrix/matrix-de-de.js'),
        slides: require('../assets/intro/de_de/slides.js'),
      },
    ],
    boxesPerRow: 5,
  },
  {
    country: 'ch',
    languages: [
      {
        language: 'de',
        file: require('../assets/languages/de_ch.json'),
        matrix: require('../assets/matrix/matrix-de-ch.js'),
        slides: require('../assets/intro/de_ch/slides.js'),
      },
    ],
    boxesPerRow: 4,
  },
];

// example return:
// {
//     de_de: language[attribute],
//     de_ch: language[attribute]
// }
export const getSupportedAttribute = (attribute) => {
  const supported = {};
  localizations.map(({ country, languages }) => {
      return languages.map(({ language, ...rest }) => {
          return supported[`${language}_${country}`] = rest[attribute];
      });
  });
  return supported;
};

// example return:
// [
//     {
//         value: "de",
//         label: t('countries:de')
//     },
// ]
export const getCountriesForPicker = (t) => {
    const countries = [];
    localizations.map(({ country }) =>
        countries.push({ value: country, label: t.language_picker['country_' + country] })
    );
    return countries;
};

// example return:
// {
//     "de": [
//         {
//             value: "de",
//             label: t('languages:de')
//         }
//     ]
// }
export const getLanguagesForPicker = (t) => {
    const countryLanguage = {};
    localizations.map(({ country, languages }) => {
        countryLanguage[country] = [];
        languages.map(({ language }) => {
            return countryLanguage[country].push({
                value: language,
                label: t.language_picker['language_' + language],
            });
        });
    });
    return countryLanguage;
};
