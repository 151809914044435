import React from "react";
import NavBar from "../NavBar/NavBar";
import TableViewCell from "../TableView/TableViewCell";
import TableViewHeader from "../TableView/TableViewHeader";
import { db } from "../data/db";
import { HIDE_LANGUAGE_SELECT } from "../data/Constants";
import { withLocalizationHook } from "src/Localization";

class InfoView extends React.Component {
	state = {
		items: [],
	};

	constructor(props) {
		super(props);

		this.showDetails = this.showDetails.bind(this);
		this.openLanguageSelector = this.openLanguageSelector.bind(this);
	}

	componentDidMount() {
		this.loadContent();
	}

	async loadContent() {

		/**
		 * @deprecated: ersetzt durch Offline Datenbank
		 */
		/* axios
			.get(
				STORYBLOK_BASE_URL +
					"apps/hazmat-storage/de_de/navigations/?version=published&token=" +
					STORYBLOK_TOKEN +
					"&cv=" +
					Date.now()
			)
			.then((result) => {
				const items = result.data.story.content.items;

				this.setState({ items, isLoading: false });
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ isLoading: false });
				}
			});
		 */


		db.table("navigations").toArray().then( async (result) =>  {
			await this.insertTutorial(result);
			this.setState({ items: result.sort(function(a, b) {return a["position"] - b["position"];}), isLoading: false });
		} )
	}

	async insertTutorial(result) {
		const { t } = this.props.localizationHook
		let tutorialPosition;
			result.map((item) => {
				if (item.group) {
					if (item.caption === t.info.informations) {
						tutorialPosition = item.position;
					}
				}
				return tutorialPosition
			}
			);
			const tutorialNavigationEntry = {
				caption: t.settings.tutorial,
				component: "app_navigation_item",
				group: false,
				is_app_route: true,
				link: {cached_url: 'intro'},
				position: tutorialPosition,
			}
		result.push(tutorialNavigationEntry);
	}

	openLanguageSelector() {
		this.props.history.push("/pickLanguage?select=cl");
	}

	showDetails(item) {
		// Storyblok sub-URLs look like this "harzardous-goods/de_de/regulations/overview-regulations"
		// We assume that external links like imprint contain http:// and should be opened directly instead of trying to render the storyblok item.
		let url = item.link.cached_url;

		if (url.includes("http")) {
			window.open(url, "_blank");
		} else if (item.is_app_route === true) {
			this.props.history.push({
				pathname: url,
				state: null,
			});
		} else {
			this.props.history.push({
				pathname: "/info/viewer",
				state: { storyblok_url: item._uid },
			});
		}
	}

	ItemList(t) {
		// Renders the list of items from the storyblok. When item.group is true, a header is displayed.
		// Next, we use the item.uid to identify the cell that is showing the feedback form, as that one is the only one that does not link to a storyblok url or an external url, but should route to the React Feedback form instead!
		return (
			<div>
				{this.state.items.map((item) =>
					{
						if (item.group) {
							return <TableViewHeader key={item.caption} title={item.caption} />
						}else{
							return <TableViewCell
								key={item.caption}
								label={item.caption}
								isLink={item?.link?.cached_url?.includes("http")}
								onClick={() => this.showDetails(item)}
							/>						
						}
					}
				)}
			</div>
		);
	}

	render() {
		const { countryLanguageString, t } = this.props.localizationHook
		return (
			<div className="content">
				<NavBar
					title={t.info.info_title}
					rightBarButtonItemTitle={
						HIDE_LANGUAGE_SELECT
							? null
							: countryLanguageString
					}
					rightBarButtonItemAction={
						HIDE_LANGUAGE_SELECT ? null : this.openLanguageSelector
					}
				/>
				{this.ItemList(t)}
			</div>
		);
	}
}

export default withLocalizationHook(InfoView);
