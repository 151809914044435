const { useLocalization } = require("src/Localization");

export const ErrorPopover = ({ showErrorPopover }) => {
  const { t } = useLocalization()
  return (
    <div
      className="errorPopover"
      style={{
        opacity: showErrorPopover ? 1 : 0,
        visibility: showErrorPopover ? "visible" : "hidden",
      }}
    >
      <p className="errorPopover-text">
        {t.general.general_error}
      </p>
    </div>
  );
}