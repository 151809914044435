import {
    getNavigationRoute,
    STORYBLOK_BASE_URL,
    STORYBLOK_TOKEN,
    APP_BASE_PATH
} from "./Constants";
import axios from "axios";
import { db } from "./db";
import { fallbackLocalization } from "src/config";

const staticRoutes = {
    hazard_classes: 'hazard-classes/hazard-classes',
    explanations: 'hazard-classes/explanations/',
    cardinfo: 'contents/h-sentence-details',
    note_text: 'contents/hinweistext-allgemeine-schutzmassnahmen',
}


class GenerateRoutes {
    static parseNavigation(lang) {
        db.clearContents().then(() => {
            const timestamp = Math.floor(Date.now() / 1000);
            const url = STORYBLOK_BASE_URL + APP_BASE_PATH + "/" + lang + "/" + getNavigationRoute + "/?version=published&token=" + STORYBLOK_TOKEN + "&cv=" + timestamp
            axios
                .get(url)
                .then((result) => {
                    const items = result.data.story.content.items;
                    if (items.length > 0) {
                        db.transaction('rw', db.navigations, () => {
                            items.map((value, key) =>
                                db.navigations.put({ _uid: value._uid, link: value.link, group: value.group, caption: value.caption, position: key, component: value.component, is_app_route: value.is_app_route })
                            )
                        }).catch(function (e) {
                            //console.log(e)
                            /**
                             * @toDo: check error
                             */
                        });
                        items.map((value) =>
                            (value.link.linktype === "story") ?
                                this.parseSubNavigation(value._uid, value.link, lang, timestamp)
                                : null
                        );
                    }
                })
                .catch((error) => {
                    console.log("parseNavigation", url, error)
                    /**
                     * @toDo: check error
                     */
                });
        })
    }

    static parseSubNavigation(uid, slug, lang, timestamp) {
        const link = STORYBLOK_BASE_URL + slug.cached_url + "?version=published&token=" + STORYBLOK_TOKEN + "&cv=" + timestamp;
        axios
            .get(
                link
            )
            .then((result) => {
                db.transaction('rw', db.contents, () => {
                    const { content, full_slug } = result.data.story || { content: null, full_slug: null }
                    if (content) db.contents.put({ _uid: uid == null ? result.data.story.content._uid : uid, content, full_slug })
                }).catch(function (e) {
                    console.log("subtask", e)
                    /**
                     * @toDo: check error
                     */
                });

            });
    }


    static getFolder(route, lang) {
        const timestamp = Math.floor(Date.now() / 1000);
        const url = STORYBLOK_BASE_URL + '?starts_with=' + APP_BASE_PATH + lang + '/' + route + '&version=published&token=' + STORYBLOK_TOKEN + '&cv=' + timestamp;

        axios.get(
            url
        ).then((result) => {
            const stories = result.data.stories
            stories.map((story) =>
                this.parseSubNavigation(null, { cached_url: story.full_slug }, lang, timestamp)
            )
        })
    }

    static downloadContent(lang = fallbackLocalization, routes = staticRoutes) {
        const timestamp = Math.floor(Date.now() / 1000);
        for (let key in routes) {
            const route = routes[key]

            if (route.slice(-1) !== '/') {
                this.parseSubNavigation(key, { cached_url: APP_BASE_PATH + lang + "/" + route }, lang, timestamp)
            } else {
                this.getFolder(route, lang)
            }

        }
    }

}

export default GenerateRoutes;
