import "./TableView.css";
import PropTypes from "prop-types";

function TableViewDropdownCell(props) {
	return (
		<div className="tableViewCell" id="text">
			{props.label}

			<select name={props.name} onChange={props.onChange} value={props.value}>
				{props.items.map((item) => item)}
			</select>
		</div>
	);
}

TableViewDropdownCell.defaultProps = {
	label: "Default Cell Label",
	text: "",
};

TableViewDropdownCell.propTypes = {
	label: PropTypes.string.isRequired, // value for the cell label.
	name: PropTypes.string.isRequired, // name of the field to handle changes.
	onChange: PropTypes.func.isRequired, // function to call when the text changes.
	items: PropTypes.array.isRequired, // the select items.
	value: PropTypes.string, // the currently selected field value.
};

export default TableViewDropdownCell;
