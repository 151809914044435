import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import {
	AUTH0_BASE_URL,
	AUTH0_CLIENT_ID,
	AUTH0_REDIRECT_URI,
} from "./data/Constants";
import firebase from "firebase/app";
import "firebase/analytics";

var firebaseConfig = {
	apiKey: "AIzaSyCGHyOc0UKfbFmpYnqccomAXmcXqX_lP6Q",
	authDomain: "denios-digital-business.firebaseapp.com",
	projectId: "denios-digital-business",
	storageBucket: "denios-digital-business.appspot.com",
	messagingSenderId: "993817644346",
	appId: "1:993817644346:web:cd5d536915dad81d58d403",
	measurementId: "G-5Q47XC5KD3",
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);


ReactDOM.render(
	<BrowserRouter>
		<Auth0Provider
			domain={AUTH0_BASE_URL}
			clientId={AUTH0_CLIENT_ID}
			redirectUri={AUTH0_REDIRECT_URI}
			useRefreshTokens={true}
			cacheLocation="localstorage"
			scope="openid profile email read:current_user update:current_user_metadata delete:user"
			audience="https://denios-hazmat-api/"
		>
			<App />
		</Auth0Provider>
	</BrowserRouter>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
