import "./TableView.css";
import PropTypes from "prop-types";

function TableViewHeader(props) {
	return <div className="tableViewHeader">{props.title}</div>;
}

TableViewHeader.propTypes = {
	title: PropTypes.string.isRequired, // Title of the header.
};

export default TableViewHeader;
