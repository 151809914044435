import React, { createContext, useState } from 'react';
import { getClassStatus, mergeArrays, sortStringArray } from './helper';
import { useLocalization } from 'src/Localization';
import { getSupportedAttribute } from 'src/config';

export const MatrixContext = createContext();

export const MatrixProvider = ({ children }) => {
    const { localization } = useLocalization();
    const { storageClasses, storageStates } =
        getSupportedAttribute('matrix')[localization];
    const [selectedClasses, setSelectedClasses] = useState([]);
    const selectedClassesCount = selectedClasses.length;

    /**
     * callback for selection and deselection of storage classes
     * updates the state
     */
    const changeSelection = (event) => {
        if (event.selected) {
            selectedClasses.push(event.classId);
            setSelectedClasses([...selectedClasses]);
        } else {
            const i = selectedClasses.findIndex((it) => it === event.classId); // find item in array
            if (i >= 0) {
                // remove item from the array
                const last = selectedClasses.pop();
                if (i < selectedClasses.length) {
                    selectedClasses[i] = last;
                }
                setSelectedClasses([...selectedClasses]);
            }
        }
    };

    const resetSelection = () => {
        setSelectedClasses([]);
    }

    let combinedClassesStr = '';
    let combinedStatus = 0;
    let combinedStatusWithoutSelfes = 1;
    const displayClasses = [];
    let allRemarks = [];
    storageClasses.forEach((storageClass) => {
        const {
            selected,
            status,
            statusWithoutSelf,
            statusOnlySelf,
            remarks,
            selfRemarks,
        } = getClassStatus({
            storageClasses,
            selectedClasses,
            storageClass,
        });
        displayClasses.push({
            classId: storageClass.classId,
            selected,
            status,
            statusOnlySelf,
            remarks,
            selfRemarks,
        });

        if (selected) {
            combinedClassesStr =
                combinedClassesStr + 'LGK ' + storageClass.classId + ' + ';

            allRemarks = mergeArrays({ array1: allRemarks, array2: remarks });

            // get highest status of selected items
            combinedStatus = combinedStatus < status ? status : combinedStatus;

            combinedStatusWithoutSelfes =
                combinedStatusWithoutSelfes < statusWithoutSelf
                    ? statusWithoutSelf
                    : combinedStatusWithoutSelfes;
        }
    });

    // remove last ' + ' from `combinedClassesStr`
    combinedClassesStr = combinedClassesStr.substring(
        0,
        combinedClassesStr.length - 3
    );

    const context = {
        selectedClassesCount,
        displayClasses,
        allRemarks: sortStringArray(allRemarks),
        combinedStatus,
        combinedStatusWithoutSelfes,
        storageStates,
        combinedClassesStr,
        changeSelection,
        resetSelection
    };

    return (
        <MatrixContext.Provider value={context}>
            {children}
        </MatrixContext.Provider>
    );
};

export const useMatrix = () => React.useContext(MatrixContext);
