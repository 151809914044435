import { React, useState, useLayoutEffect } from "react";
import "./MatrixView.css";
import NavBar from "../NavBar/NavBar";
import { useHistory } from "react-router-dom";
import MatrixGrid from "./MatrixGrid";
import { MATRIX_HELP_URL } from "../data/Constants";
import OfferForm from "../Forms/OfferForm";
import DetailsForm from "../Forms/DetailsForm";
import InfoDetailView from "../Reusable Views/InfoDetailView";
import Modal from "../Reusable Views/Modal/Modal";
import { HIDE_LANGUAGE_SELECT } from "../data/Constants";
import FirebaseManager from "../FirebaseManager";
import { useMatrix } from "../useMatrix";
import { useLocalization } from "src/Localization";

function MatrixView() {
	let history = useHistory();
	const { selectedClassesCount, combinedClassesStr } = useMatrix()
	const { t, countryLanguageString } = useLocalization()

	const [showOfferForm, setShowOfferForm] = useState(false);
	const [showDetailsForm, setShowDetailsForm] = useState(false);
	const [showMatrixHelp, setShowMatrixHelp] = useState(false);

	// Make sure the centered modal becomes scrollable, when the window height is smaller than the modal. Otherwise it would be cut off.
	useLayoutEffect(() => {
		function updateSize() {
			document.querySelectorAll(".matrixGrid-grid").forEach((outerElement) => {
				document
					.querySelectorAll(".matrixGrid-container")
					.forEach((element) => {
						if (window.innerHeight < outerElement.offsetHeight + 200) {
							element.removeAttribute("id");
						} else {
							element.id = "center";
						}
					});
			});
		}

		window.addEventListener("resize", updateSize);
		updateSize();
		return () => window.removeEventListener("resize", updateSize);
	}, []);

	function toggleDetailsForm() {
		setShowDetailsForm(!showDetailsForm);
	}

	function toggleOfferForm() {
		if (!showOfferForm) {
			FirebaseManager.logEvent(FirebaseManager.OFFER, {
				klassen: combinedClassesStr,
			});
		}

		setShowOfferForm(!showOfferForm);
	}

	function openLanguageSelector() {
		history.push("/pickLanguage?select=cl");
	}

	function toggleMatrixHelp() {
		setShowMatrixHelp(!showMatrixHelp);
	}

	function showDisclaimer() {
		history.push({
			pathname: "/info/viewer",
			state: {
				storyblok_url: "note_text",
			},
		});
	}

	function ButtonBarResult() {
		return (
			<div className="matrixGrid-buttonBarResult">
				<button onClick={toggleDetailsForm}>
					{t.matrix.result}
				</button>
			</div>
		);
	}

	return (
		<div className="matrixGrid-wrapper">
			<NavBar
				showLogo={true}
				rightBarButtonItemTitle={
					HIDE_LANGUAGE_SELECT
						? null
						: countryLanguageString
				}
				rightBarButtonItemAction={
					HIDE_LANGUAGE_SELECT ? null : openLanguageSelector
				}
			/>

			<div className="matrixGrid-container">
				<div className="matrixGrid-grid">
					<p className="matrixGrid-header">{t.matrix.header}</p>
					<MatrixGrid />
					<p className="matrixGrid-footer" onClick={showDisclaimer}>
						{t.matrix.footer}
					</p>
				</div>
			</div>

			{selectedClassesCount > 0 && ButtonBarResult()}

			{showDetailsForm && (
				<Modal>
					<DetailsForm
						showCancelButton={true}
						cancelHandler={toggleDetailsForm}
						showMatrixHelp={toggleMatrixHelp}
					/>
				</Modal>
			)}

			{showOfferForm && (
				<Modal>
					<OfferForm showCancelButton={true} cancelHandler={toggleOfferForm} />
				</Modal>
			)}

			{showMatrixHelp && (
				<Modal>
					<InfoDetailView
						url={MATRIX_HELP_URL}
						showCancelButton={true}
						cancelHandler={toggleMatrixHelp}
					/>
				</Modal>
			)}
		</div>
	);
}

export default MatrixView;
