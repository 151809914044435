import React from "react";
import { Explanation } from "./components";

const MatrixWarningsView = ({ remarks }) => {
  const views = [];
  for (let remark of remarks) {
    views.push(<Explanation key={remark} remark={remark} />);
  }
  return <div>{views}</div>;
};

export default MatrixWarningsView;
