import ClipLoader from "react-spinners/ClipLoader";

function LoadingView(props) {
  return (
    <div className="loadingView">
      <ClipLoader color="#2061ae" loading={true} size={60} />
    </div>
  );
}

export default LoadingView;
