import { useEffect, useState } from "react";
import { useMatrix } from "src/useMatrix";
import { db } from "src/data/db";
import StoryblokRenderer from "src/Reusable Views/StoryblokRenderer";

export const GeneralExplanation = ({ displayedStatus }) => {
  const [story, setStory] = useState(null);
  const { storageStates } = useMatrix();

  useEffect(() => {
    const colorName = () => {
      if (displayedStatus === storageStates.STATE_GREEN) return "green";
      else if (displayedStatus === storageStates.STATE_YELLOW) return "yellow";
      else if (displayedStatus === storageStates.STATE_RED) return "red";
      return "";
    };
    const loadRemark = async () => {
      setStory(
        await db.getContentByRoute(
          `/hazard-classes/explanations/${colorName()}`
        )
      );
    };
    loadRemark();
  }, [displayedStatus, storageStates]);

  if (!story) return null;
  return <StoryblokRenderer content={story.content.contents} />;
};
