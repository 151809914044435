import React from "react";
import NavBar from "../NavBar/NavBar";
import TableViewHeader from "../TableView/TableViewHeader";
import TableViewFooter from "../TableView/TableViewFooter";
import TableItem from "./components/TableItem";
import { getCountriesForPicker, getLanguagesForPicker } from "src/config";
import { useLocalization } from "src/Localization";
import queryString from 'query-string';
import { useMatrix } from 'src/useMatrix';

const LanguagePicker = ({ location, history }) => {
	const { t, country, language, setCountry, setLanguage } = useLocalization()
	const pickerCountries = getCountriesForPicker(t);
	const pickerLanguages = getLanguagesForPicker(t);
	let params = queryString.parse(location.search)
	const isCountry = params?.select?.includes("c")
	const isLanguage= params?.select?.includes("l")
    const { resetSelection } = useMatrix();

	const save = () => {
		history.goBack();
	}

	const title = () => {
		if (isCountry && isLanguage) return "country_language"
		if (isCountry) return "country"
		if (isLanguage) return "language"
		return ""
	} 

	return (
		<div className="content">
			<NavBar
				title={t.language_picker[title()]}
				rightBarButtonItemTitle={t.general.done}
				rightBarButtonItemAction={save}
			/>
			{isCountry &&
				<>
					<TableViewHeader title={t.language_picker.country} />
					{pickerCountries.map(({value, label}) => (
						<TableItem
						key={value}
						label={label}
						onClick={() => {setCountry(value); resetSelection()}}
						selected={country === value}
						/>
						))}
					<TableViewFooter title={t.language_picker.footer} />
				</>
			}
			{isLanguage &&
				<>
					<TableViewHeader title={t.language_picker.language} />
					{pickerLanguages[country].map(({value, label}) => (
						<TableItem
							key={value}
							label={label}
							onClick={() => setLanguage(value)}
							selected={language === value}
						/>
					))}
				</>
			}
		</div>
	);
}



export default LanguagePicker;
