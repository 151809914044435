import { MatrixInformationContent } from "./MatrixInformationContent";
import "./MatrixInformation.css";
import { useMatrix } from "src/useMatrix";
import { useLocalization } from "src/Localization";
import { GeneralExplanation } from "../GeneralExplanation/GeneralExplanation";

export const MatrixInformation = ({
  allRemarks,
  status,
  classesStr,
  classesCombined = false,
}) => {
  const { t } = useLocalization()
  const { storageStates } = useMatrix()

  const idForStatus = (status) => {
    if (status === storageStates.STATE_NONE) {
      return null;
    }
    switch (status) {
      case "1":
        return "stateGreen";
      case "2":
        return "stateYellow";
      case "3":
        return "stateRed";
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="matrixGrid-noteBox">
        <div
          className="matrixGrid-noteBox-icon"
          id={
            status === "1"
              ? "allowed"
              : status === "2"
              ? "restricted"
              : "notAllowed"
          }
        />
        <p style={{ fontSize: "16px" }}>
          {status === "1" ? (
            t.formatString(t.details.allowed, {
              underline: (
                <span style={{ fontWeight: "bold" }}>
                  {t.details.allowed2}
                </span>
              ),
            })
          ) : status === "2" ? (
            <div>
              {classesCombined
                ? t.formatString(t.details.restricted, {
                    underline: (
                      <span style={{ fontWeight: "bold" }}>
                        {t.details.restricted2}
                      </span>
                    ),
                  })
                : t.formatString(
                    t.details.restrictedSelf,
                    {
                      underline1: (
                        <span style={{ fontWeight: "bold" }}>
                          {t.formatString(
                            t.details.restrictedSelf2,
                            {
                              selectedClassesStr: classesStr,
                            }
                          )}
                        </span>
                      ),
                      underline2: (
                        <span style={{ fontWeight: "bold" }}>
                          {t.details.restrictedSelf3}
                        </span>
                      ),
                    }
                  )}
            </div>
          ) : (
            t.formatString(t.details.not_allowed, {
              underline: (
                <span style={{ fontWeight: "bold" }}>
                  {t.details.not_allowed2}
                </span>
              ),
            })
          )}
        </p>
      </div>
      {classesCombined && <GeneralExplanation displayedStatus={status} />}
      <div className="matrixGrid-helpBox" id={idForStatus(status)}>
        <div className="matrixGrid-helpBox-content" id={idForStatus(status)}>
          <MatrixInformationContent
            allRemarks={allRemarks}
            status={status}
            classesStr={classesStr}
            classesCombined={classesCombined}
          />
        </div>
      </div>
    </div>
  );
};
