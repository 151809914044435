import "./TableView.css";
import PropTypes from "prop-types";

function TableViewMultilineCell(props) {
	return (
		<div className="tableViewCell" id="text">
			<textarea
				placeholder={props.label}
				rows="10"
				value={props.text}
				name={props.name}
				onChange={props.onChange}
			/>
		</div>
	);
}

TableViewMultilineCell.defaultProps = {
	label: "Default Cell Label",
	text: "",
};

TableViewMultilineCell.propTypes = {
	label: PropTypes.string.isRequired, // value for the cell label.
	text: PropTypes.string, // the text field content
	name: PropTypes.string.isRequired, // name of the field to handle changes.
	onChange: PropTypes.func.isRequired, // function to call when the text changes.
};

export default TableViewMultilineCell;
