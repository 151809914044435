import "./TableView.css";
import PropTypes from "prop-types";

function TableViewFooter(props) {
	return <div className="tableViewFooter">{props.title}</div>;
}

TableViewFooter.propTypes = {
	title: PropTypes.string.isRequired, // Title of the footer.
};

export default TableViewFooter;
