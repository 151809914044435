import React from "react";
import NavBar from "../NavBar/NavBar";
import { withRouter } from "react-router-dom";
import StoryblokView from "./StoryblokView";
import { withLocalizationHook } from "src/Localization";

class InfoDetailView extends React.Component {
	render() {
		const { t } = this.props.localizationHook
		return (
			<div className="content">
				<NavBar
					title=""
					backButtonTitle={
						this.props.showCancelButton ? null : t.info.info_title
					}
					leftBarButtonItemTitle={
						this.props.showCancelButton ? t.general.cancel : null
					}
					leftBarButtonItemAction={
						this.props.showCancelButton ? this.props.cancelHandler : null
					}
				/>

				<StoryblokView
					url={
						this.props.location.state
							? this.props.location.state.storyblok_url
							: this.props.url
					}
				/>
			</div>
		);
	}
}

export default withRouter(withLocalizationHook(InfoDetailView));
