// add item to array if it doesn't exist already
const addUnique = ({ array, item }) => {
    if (item && !array.find((i) => i === item)) {
        array.push(item);
    }
    return array;
};

export const sortStringArray = (array) => {
    return array.sort((x, y) => Number(Number(x) - Number(y)));
};

export const mergeArrays = ({ array1, array2 }) => {
    array1.forEach((itemFromArray1) => {
        array2 = addUnique({
            array: array2,
            item: itemFromArray1,
        });
    });
    return array2;
};

export const getClassStatus = ({
    storageClasses,
    selectedClasses,
    storageClass,
}) => {
    let status = 0;
    let statusWithoutSelf = 0;
    let statusOnlySelf = 0;
    let selected = false;
    let remarks = [];
    let selfRemarks = [];
    selectedClasses.forEach((selectedClassId) => {
        selected = selected || selectedClassId === storageClass.classId;

        const selectedClass = storageClasses.find(
            (i) => i.classId === selectedClassId
        );
        if (!selectedClass) return;

        let combo = selectedClass.combinations.find(
            (i) => i.classId === storageClass.classId
        );
        if (!combo) return;

        status = combo.status > status ? combo.status : status;
        if (storageClass.classId !== selectedClass.classId) {
            statusWithoutSelf =
                combo.status > statusWithoutSelf
                    ? combo.status
                    : statusWithoutSelf;

            remarks = addUnique({
                array: remarks,
                item: combo.remarks,
            });
        } else {
            statusOnlySelf =
                combo.status > statusOnlySelf
                    ? combo.status
                    : statusOnlySelf;
            selfRemarks = addUnique({
                array: selfRemarks,
                item: combo.remarks,
            });
        }
    });
    return {
        status,
        statusWithoutSelf,
        statusOnlySelf,
        selected,
        remarks,
        selfRemarks,
    };
};
