import "./ModalBox.css";
import PropTypes from "prop-types";

function ModalBox(props) {
  return (
    <div className="modalBox-overlay">
      <div className="modalBox">
        <p className="modalBox-title">{props.title}</p>
        <hr />
        <div className="modalBox-content">{props.children}</div>
      </div>
    </div>
  );
}

ModalBox.propTypes = {
  title: PropTypes.string.isRequired,
};

export default ModalBox;
