import React from "react";
import LoadingView from "../Reusable Views/LoadingView";
import PropTypes from "prop-types";
import StoryblokRenderer from "./StoryblokRenderer";
import {db} from "../data/db";
import { withLocalizationHook } from "src/Localization";

class StoryblokView extends React.Component {
	state = {
		isLoading: true,
		content: null,
		error: false,
	};

	componentDidMount() {
		this.loadContent(this.props.url);
	}

	async loadContent(url) {

		let result = await db.table("contents").where({_uid: url}).first();

		if (result.content.component === "app_page") {
			this.setState({ content: result.content.contents, isLoading: false });
		} else if (
			result.content.component === "app_hazard_class_list"
		) {

			const content = result.content;
			this.setState({ content, isLoading: false });
		} else {
			this.setState({ error: true });
		}


		//this.setState({ items: result, isLoading: false });
		/**
		 * @deprecated: ersetzt durch Offline Datenbank
		 */
		/*axios
			.get(
				STORYBLOK_BASE_URL +
					url +
					"?version=published&token=" +
					STORYBLOK_TOKEN +
					"&cv=" +
					Date.now()
			)
			.then((result) => {
				if (result.data.story.content.component === "app_page") {
					const content = result.data.story.content.contents;
					this.setState({ content, isLoading: false });
				} else if (
					result.data.story.content.component === "app_hazard_class_list"
				) {
					const content = result.data.story.content;
					this.setState({ content, isLoading: false });
				} else {
					this.setState({ error: true });
				}
			})
			.catch((error) => {
				if (error.response) {
					this.setState({ error: true, isLoading: false });
				}
			});*/
	}

	render() {
		const { t } = this.props.localizationHook
		return (
			<div className="paddedContent">
				{this.state.content === null ? (
					this.state.error ? (
						<p>{t.general.general_error}</p>
					) : (
						<LoadingView />
					)
				) : (
					<StoryblokRenderer content={this.state.content} />
				)}
			</div>
		);
	}
}

StoryblokView.propTypes = {
	url: PropTypes.string.isRequired,
};

export default withLocalizationHook(StoryblokView);
