import { useLocalization } from "src/Localization";
import { useMatrix } from "../useMatrix";
import { MatrixInformation } from "./components";
//import { useAuth0 } from "@auth0/auth0-react";

function MatrixInfoView() {
  const { t } = useLocalization();
  //const { user, isAuthenticated } = useAuth0();
  const {
    combinedClassesStr,
    allRemarks,
    combinedStatusWithoutSelfes,
    selectedClassesCount,
    displayClasses,
  } = useMatrix();

  const selectedClasses = displayClasses.filter(
    (item) => item.selected && item.selfRemarks > 0
  );
  return (
    <div>
      <span style={{ fontSize: 14, display: "block" }}>
        {t.details.subtitle}
      </span>

      {/* show a combined status if no single status is available or if more than one class is selected */}
      {(selectedClasses.length === 0 || selectedClassesCount !== 1) && (
        <MatrixInformation
          allRemarks={allRemarks}
          status={combinedStatusWithoutSelfes.toString()}
          classesStr={combinedClassesStr}
          classesCombined
        />
      )}

      {selectedClasses.map((item, i) => {
        return (
          <MatrixInformation
            key={i.toString()}
            allRemarks={item.selfRemarks}
            status={item.statusOnlySelf}
            classesStr={"LGK " + item.classId}
          />
        );
      })}
    </div>
  );
}

export default MatrixInfoView;
