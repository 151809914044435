import React from "react";
import { MAIL_SERVICE_URL, PRIVACY_URL } from "../data/Constants";
import NavBar from "../NavBar/NavBar";
import axios from "axios";
import TableViewHeader from "../TableView/TableViewHeader";
import TableViewTextCell from "../TableView/TableViewTextCell";
import TableViewMultilineCell from "../TableView/TableViewMultilineCell";
import LoadingView from "../Reusable Views/LoadingView";
import { withAuth0 } from "@auth0/auth0-react";
import FirebaseManager from "../FirebaseManager";
import { withLocalizationHook } from "src/Localization";

class FeedbackForm extends React.Component {
	state = {
		firstname: "",
		lastname: "",
		company: "",
		email: "",
		message: "",
		isSent: false,
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.sendForm = this.sendForm.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	componentDidMount() {
		let auth0 = this.props.auth0;

		if (auth0.isAuthenticated) {
			let firstname =
				auth0.user["https://hazmat.denios.sitegeist.rocks/first_name"] ?? "";
			let lastname =
				auth0.user["https://hazmat.denios.sitegeist.rocks/last_name"] ?? "";
			let email = auth0.user.email ?? "";

			this.setState({
				firstname: firstname,
				lastname: lastname,
				email: email,
			});
		}
	}

	goBack() {
		this.props.history.goBack();
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
		});
	}

	sendForm() {
		this.setState({ isSending: true });

		axios
			.post(MAIL_SERVICE_URL, {
				formName: "Feedback_Form",
				fields: [
					{
						fieldName: "firstname",
						fieldValue: this.state.firstname,
					},
					{
						fieldName: "lastname",
						fieldValue: this.state.lastname,
					},
					{
						fieldName: "company",
						fieldValue: this.state.company,
					},
					{
						fieldName: "email",
						fieldValue: this.state.email,
					},
					{
						fieldName: "message",
						fieldValue: this.state.message,
					},
				],
			})
			.then(() => {
				FirebaseManager.logEvent(FirebaseManager.FEEDBACK);

				this.setState({ isSending: false, isSent: true });
			})
			.catch((error) => {
				this.setState({ isSending: false });
			});
	}

	Form() {
		const { t } = this.props.localizationHook;

		return (
			<div>
				<div className="paddedContent">
					<p className="smallTitle">{t.forms.feedback_subtitle}</p>
				</div>

				<TableViewHeader title={t.forms.your_data} />
				<TableViewTextCell
					name="firstname"
					label={t.forms.firstname}
					text={this.state.firstname}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="lastname"
					label={t.forms.lastname}
					text={this.state.lastname}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="company"
					label={t.forms.company}
					text={this.state.company}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="email"
					label={t.forms.email}
					text={this.state.email}
					onChange={this.handleChange}
				/>

				<TableViewHeader title={t.forms.message} />
				<TableViewMultilineCell
					name="message"
					label={t.forms.message}
					text={this.state.message}
					onChange={this.handleChange}
				/>

				<div className="paddedContent" style={{ marginTop: "20px" }}>
					<p className="text">
						{t.formatString(t.forms.privacy_notice, {
							link: (
								<a href={PRIVACY_URL} target="_blank" rel="noreferrer">
									{t.forms.privacy_link}
								</a>
							),
						})}
					</p>
				</div>
			</div>
		);
	}

	SuccessPopover() {
		return (
			<div className="successPopover">
				<p className="smallTitle" id="center" style={{ marginBottom: "30px" }}>
					Ihre Nachricht wurde verschickt.
				</p>

				<button onClick={this.goBack}>Fertig</button>
			</div>
		);
	}

	render() {
		const { t } = this.props.localizationHook;

		return (
			<div className="content">
				<NavBar
					title={t.forms.feedback_title}
					backButtonTitle={t.general.back}
					rightBarButtonItemTitle={
						this.state.isSent ? null : t.general.send
					}
					rightBarButtonIsConfirm={true}
					rightBarButtonItemAction={this.sendForm}
					disableRightBarButtonItem={
						this.state.firstname.length === 0 ||
						this.state.lastname.length === 0 ||
						this.state.email.length === 0 ||
						this.state.message.length === 0 ||
						this.state.isSending
					}
				/>

				{this.state.isSending ? (
					<LoadingView />
				) : this.state.isSent ? (
					this.SuccessPopover()
				) : (
					this.Form()
				)}
			</div>
		);
	}
}

export default withAuth0(withLocalizationHook(FeedbackForm));
