import React from "react";
import "./App.css";
import { Switch, Route } from "react-router-dom";
import InfoView from "./InfoView/InfoView";
import SettingsView from "./SettingsView/SettingsView";
import ProfileForm from "./Forms/ProfileForm";
import MatrixView from "./MatrixView/MatrixView";
import LanguagePicker from "./LanguagePicker/LanguagePicker";
import FeedbackForm from "./Forms/FeedbackForm";
import OfferForm from "./Forms/OfferForm";
import InfoDetailView from "./Reusable Views/InfoDetailView";
import LoginResponse from "./LoginResponse";
import TabBar from "./TabBar/TabBar";
import LaunchView from "./LaunchView";
import UsageCountReminderBox from "./Reusable Views/UsageCountReminderBox";
import Cookies from "js-cookie";
import { DISABLE_LIMITED_USAGE } from "./data/Constants";
import { withRouter } from "react-router-dom";
import { withAuth0 } from "@auth0/auth0-react";
import LoadingView from "./Reusable Views/LoadingView";
import axios from "axios";
import { MatrixProvider } from "./useMatrix";
import { LocalizationProvider, withLocalizationHook } from "./Localization";
import { ErrorPopover } from "./components";
import Intro from "./IntroView/index";

class App extends React.Component {
	state = {
		showUsageReminderBox: true,
		showErrorPopover: false,
	};

	constructor(props) {
		super(props);

		this.closeUsageCountReminderBox =
			this.closeUsageCountReminderBox.bind(this);
		this.isLaunchRoute = this.isLaunchRoute.bind(this);
		this.isIntroRoute = this.isIntroRoute.bind(this);
		this.displayErrorPopover = this.displayErrorPopover.bind(this);
	}

	componentDidMount() {
		this.increaseUsageCount();

		var self = this;

		axios.interceptors.response.use(
			function (response) {
				return response;
			},
			function (error) {
				self.displayErrorPopover();

				return Promise.reject(error);
			}
		);
	}

	displayErrorPopover() {
		this.setState({ showErrorPopover: true });

		setTimeout(
			function () {
				this.setState({ showErrorPopover: false });
			}.bind(this),
			1900
		);
	}

	// Increase count in a cookie to keep track of the number of times a user has visited the app.
	increaseUsageCount() {
		// This is a debug option to temporarily disable limited usage. This is false in production and can be set in Constants.js
		if (DISABLE_LIMITED_USAGE) {
			return;
		}

		let usedCount = parseInt(Cookies.get("AppUsageCount") ?? 0, 10);
		Cookies.set("AppUsageCount", usedCount + 1);
	}

	closeUsageCountReminderBox() {
		this.setState({ showUsageReminderBox: false });
	}

	isLaunchRoute() {
		return (
			this.props.location.pathname === "/" ||
			this.props.location.pathname.includes("/launch")
		);
	}

	isIntroRoute() {
		return (
			this.props.location.pathname.includes("/intro")
		);
	}


	UsageReminderBox() {
		let auth0 = this.props.auth0;

		// Only display usage reminder box when:
		// 1. User is not logged in
		// 2. User is not at the launch screen
		// 3. User has not closed the box

		return (
			<div>
				{!auth0.isAuthenticated &&
					this.state.showUsageReminderBox === true &&
					!this.isLaunchRoute() && (
						<UsageCountReminderBox
							closeFunction={this.closeUsageCountReminderBox}
						/>
					)}
			</div>
		);
	}


	render() {
		let auth0 = this.props.auth0;

		if (auth0.isLoading) {
			return <LoadingView />;
		}

		return (
			<div>
				<LocalizationProvider>
					<MatrixProvider>
						<Switch>
							<Route exact path="/" component={LaunchView} />
							<Route exact path="/loggedin" component={LoginResponse} />

							<Route exact path="/info" component={InfoView} />
							<Route exact path="/info/viewer" component={InfoDetailView} />
							<Route exact path="/info/viewer/:id" component={InfoDetailView} />

							<Route exact path="/matrix" component={MatrixView} />

							<Route exact path="/settings" component={SettingsView} />
							<Route exact path="/profile/edit" component={ProfileForm} />

							<Route exact path="/feedback" component={FeedbackForm} />
							<Route exact path="/offer" component={OfferForm} />

							<Route exact path="/pickLanguage" component={LanguagePicker} />
							<Route exact path="/launch/pickLanguage" component={LanguagePicker} />

							<Route exact path="/intro" component={Intro} />

						</Switch>
					</MatrixProvider>

					{(!this.isLaunchRoute() && !this.isIntroRoute()) ?  <TabBar /> : null }

					{this.UsageReminderBox()}

					<ErrorPopover showErrorPopover={this.state.showErrorPopover}/>
				</LocalizationProvider>
			</div>
		);
	}
}

export default withAuth0(withRouter(withLocalizationHook(App)));
