export const storageStates = {
  STATE_NONE: "0",
  STATE_GREEN: "1",
  STATE_YELLOW: "2",
  STATE_RED: "3",
};

//const STATE_NONE = storageStates.STATE_NONE;
const STATE_GREEN = storageStates.STATE_GREEN;
const STATE_YELLOW = storageStates.STATE_YELLOW;
const STATE_RED = storageStates.STATE_RED;

export const storageClasses = [
  {
    classId: "1",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_RED,
        remarks: null,
      },
    ],
  },
  {
    classId: "2A",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_YELLOW,
        remarks: "3",
      },
      {
        classId: "2B",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "2",
      },
    ],
  },
  {
    classId: "2B",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "3",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "5",
      },
    ],
  },
  {
    classId: "4.1A",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "8B",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "10",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "12",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "13",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "1",
      },
    ],
  },
  {
    classId: "4.1B",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "6.1A",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "4.2",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.2",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.1D",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "8B",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "10",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "6",
      },
    ],
  },
  {
    classId: "4.3",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.1D",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "8B",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "10",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "12",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "6",
      },
    ],
  },
  {
    classId: "5.1A",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_RED,
        remarks: null,
      },
    ],
  },
  {
    classId: "5.1B",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "6.1B",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "6.1C",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.1D",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "7",
      },
    ],
  },
  {
    classId: "5.1C",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "2B",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "8B",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "10",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "12",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "13",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "1",
      },
    ],
  },
  {
    classId: "5.2",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "1",
      },
    ],
  },
  {
    classId: "6.1A",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_YELLOW,
        remarks: "8",
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "5",
      },
    ],
  },
  {
    classId: "6.1B",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "4",
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_YELLOW,
        remarks: "5",
      },
    ],
  },
  {
    classId: "6.1C",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "6.1D",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "6.2",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_RED,
        remarks: null,
      },
    ],
  },
  {
    classId: "7",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "8A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_RED,
        remarks: null,
      },
    ],
  },
  {
    classId: "8A",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "8B",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "10",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "11",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "6.1A",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "6.1B",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "12",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "13",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.3",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
  {
    classId: "10-13",
    selected: false,
    status: STATE_GREEN,
    combinations: [
      {
        classId: "1",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "2A",
        status: STATE_YELLOW,
        remarks: "2",
      },
      {
        classId: "2B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "3",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "4.1A",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "4.1B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "4.2",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "4.3",
        status: STATE_YELLOW,
        remarks: "6",
      },
      {
        classId: "5.1A",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "5.1B",
        status: STATE_YELLOW,
        remarks: "7",
      },
      {
        classId: "5.1C",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "5.2",
        status: STATE_YELLOW,
        remarks: "1",
      },
      {
        classId: "6.1A",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "6.1B",
        status: STATE_YELLOW,
        remarks: "5",
      },
      {
        classId: "6.1C",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.1D",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "6.2",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "7",
        status: STATE_RED,
        remarks: null,
      },
      {
        classId: "8A",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "8B",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "11",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "12",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "13",
        status: STATE_GREEN,
        remarks: null,
      },
      {
        classId: "10-13",
        status: STATE_GREEN,
        remarks: null,
      },
    ],
  },
];
