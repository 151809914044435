import React from "react";
import { REACT_APP_MAIL_SERVICE_BASE_URL } from "./data/Constants";
import LoadingView from "./Reusable Views/LoadingView";
import { withAuth0 } from "@auth0/auth0-react";
import FirebaseManager from "./FirebaseManager";

class LoginResponse extends React.Component {

	componentDidMount() {
		this.loginResponse();
	}

	async loginResponse() {
		let auth0 = this.props.auth0;

		try {
			const accessToken = await auth0.getAccessTokenSilently();

			const userDetailsByIdUrl =
				"https://" +
				REACT_APP_MAIL_SERVICE_BASE_URL +
				"/api/v2/users/" +
				auth0.user.sub;

			const metadataResponse = await fetch(userDetailsByIdUrl, {
				headers: {
					Authorization: `Bearer ${accessToken}`,
				},
			});

			let response = await metadataResponse.json();

			if (response.logins_count === 1) {
				FirebaseManager.logEvent(FirebaseManager.REGISTERED);
			} else {
				FirebaseManager.logEvent(FirebaseManager.LOGGED_IN);
			}

			this.props.history.push("/");
		} catch (e) {
			console.log(e.message);
		}
	}

	render() {
		return (
			<div className="content">
				<LoadingView />
			</div>
		);
	}
}

export default withAuth0(LoginResponse);
