import firebase from "firebase/app";
import "firebase/analytics";

class FirebaseManager {
	static LOGGED_IN = "Login";
	static REGISTERED = "SignIn";
	static USER_TRIAL = "kostenlos";
	static CONSULTATION = "beratung";
	static OFFER = "angebot";
	static FEEDBACK = "feedback";

	static logEvent(name, data) {
		firebase.analytics().logEvent(name, data ?? null);
	}
}

export default FirebaseManager;
