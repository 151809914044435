import React, { useState } from "react";
import NavBar from "../NavBar/NavBar";
import TableViewCell from "../TableView/TableViewCell";
import TableViewHeader from "../TableView/TableViewHeader";
import { VERSION_NUMBER } from "../data/Constants";
import { withAuth0 } from "@auth0/auth0-react";
import LoadingView from "../Reusable Views/LoadingView";
import DeleteAccountBox from "./DeleteAccountBox";
import { HIDE_LANGUAGE_SELECT } from "../data/Constants";
import { useLocalization } from "../Localization";

const SettingsView = (props) => {
	const { language, country, t } = useLocalization()
	const [showDeleteAccountBox, setShowDeleteAccountBox] = useState(false);
	const auth0 = props.auth0;

	const UserActions = () => {
		return (
			<div>
				<TableViewHeader title={t.settings.profile} />
				<TableViewCell
					label={t.settings.edit_profile}
					onClick={showEditProfile}
				/>
				<TableViewCell
					label={t.settings.delete_profile}
					onClick={toggleDeleteAccountBox}
				/>
			</div>
		);
	}

	const Content = () => {
		let auth0 = props.auth0;
		return (
			<div>
				{auth0.isAuthenticated === true && (
					UserActions()
				)}
			</div>
		);
	}

	const AuthenticationButton = () => {
		let auth0 = props.auth0;
		return (
			<div className="paddedContent" style={{ position: "static" }}>
				{auth0.isAuthenticated ?
					<button
						className="outline bottomButton"
						onClick={() => auth0.logout({ returnTo: window.location.origin })}
					>
						{t.settings.logout}
					</button>

					:
					<button
						className="bottomButton"
						onClick={() =>
							auth0.loginWithRedirect({
								ui_locales: language,
							})
						}
					>
						{t.general.login}
					</button>
				}
			</div>
		)
	}

	const openLanguageSelector = () => {
		props.history.push("/pickLanguage?select=cl");
	}

	const toggleDeleteAccountBox = () => {
		setShowDeleteAccountBox(!showDeleteAccountBox)
	}

	const showEditProfile = () => {
		props.history.push("/profile/edit");
	}

	return (
		<div className="content">
			<NavBar
				title={t.settings.title}
				rightBarButtonItemTitle={
					HIDE_LANGUAGE_SELECT
						? null
						: country
				}
				rightBarButtonItemAction={
					HIDE_LANGUAGE_SELECT ? null : openLanguageSelector
				}
			/>
			{!HIDE_LANGUAGE_SELECT &&
				<div style={{ marginBottom: "20px" }}>
					<TableViewHeader title={t.settings.general} />
					<TableViewCell
						label={t.settings.country_language}
						onClick={openLanguageSelector}
					/>
					<TableViewCell
						isLabel={true}
						label={"Version " + VERSION_NUMBER}
					/>
				</div>

			}
			{auth0.isLoading ? <LoadingView /> : Content()}

			{showDeleteAccountBox && (
				<DeleteAccountBox closeFunction={toggleDeleteAccountBox} />
			)}
			{AuthenticationButton()}
		</div>
	);
}

export default withAuth0(SettingsView);
