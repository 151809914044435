import MatrixGridItem from "./MatrixGridItem";
import "./MatrixGrid.css";
import { useMatrix } from "../useMatrix";
import { useLocalization } from "../Localization/index";

function MatrixGrid() {
	const { displayClasses } = useMatrix();
	const { boxesPerRow } = useLocalization();
	return (
		<div className="matrixGrid"
			style={{
				maxWidth: window.innerWidth < 500 ? window.innerWidth : 500,
				gridTemplateColumns: `repeat(${boxesPerRow}, 1fr)`
			}}
		>
			{displayClasses.map((item) => (
				<MatrixGridItem key={item.classId} item={item} />
			))}
		</div>
	);
}

export default MatrixGrid;
