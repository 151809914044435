import React from "react";
import NavBar from "../NavBar/NavBar";
import axios from "axios";
import TableViewHeader from "../TableView/TableViewHeader";
import TableViewTextCell from "../TableView/TableViewTextCell";
import { MAIL_SERVICE_URL, PRIVACY_URL } from "../data/Constants";
import LoadingView from "../Reusable Views/LoadingView";
import TableViewMultilineCell from "../TableView/TableViewMultilineCell";
import { withAuth0 } from "@auth0/auth0-react";
import { withRouter } from "react-router-dom";
import { withLocalizationHook } from "src/Localization";


class OfferForm extends React.Component {
	state = {
		firstname: "",
		lastname: "",
		company: "",
		zip: "",
		email: "",
		phone: "",
		message: "",
		isSent: false,
	};

	constructor(props) {
		super(props);

		this.handleChange = this.handleChange.bind(this);
		this.sendForm = this.sendForm.bind(this);
		this.goBack = this.goBack.bind(this);
	}

	componentDidMount() {
		let auth0 = this.props.auth0;

		if (auth0.isAuthenticated) {
			let firstname =
				auth0.user["https://hazmat.denios.sitegeist.rocks/first_name"] ?? "";
			let lastname =
				auth0.user["https://hazmat.denios.sitegeist.rocks/last_name"] ?? "";
			let email = auth0.user.email ?? "";

			this.setState({
				firstname: firstname,
				lastname: lastname,
				email: email,
			});
		}
	}

	handleChange(event) {
		const value = event.target.value;
		this.setState({
			...this.state,
			[event.target.name]: value,
		});
	}

	goBack() {
		this.props.history.goBack();
	}

	sendForm() {
		this.setState({ isSending: true });

		axios
			.post(MAIL_SERVICE_URL, {
				formName: "Offer_Form",
				fields: [
					{
						fieldName: "firstname",
						fieldValue: this.state.firstname,
					},
					{
						fieldName: "lastname",
						fieldValue: this.state.lastname,
					},
					{
						fieldName: "company",
						fieldValue: this.state.company,
					},
					{
						fieldName: "zip",
						fieldValue: this.state.zip,
					},
					{
						fieldName: "email",
						fieldValue: this.state.email,
					},
					{
						fieldName: "phone",
						fieldValue: this.state.phone,
					},
					{
						fieldName: "message",
						fieldValue: this.state.message,
					},
				],
			})
			.then(() => {
				this.setState({ isSending: false, isSent: true });
			})
			.catch((error) => {
				this.setState({ isSending: false, isSent: false });
			});
	}

	Form() {
		const { t } = this.props.localizationHook;

		return (
			<div>
				<div className="paddedContent">
					<p className="smallTitle">{t.forms.offer_title}</p>
					<p className="text">{t.forms.offer_subtitle}</p>

				</div>

				<TableViewHeader title={t.forms.your_data} />
				<TableViewTextCell
					name="firstname"
					label={t.forms.firstname}
					text={this.state.firstname}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="lastname"
					label={t.forms.lastname}
					text={this.state.lastname}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="company"
					label={t.forms.company}
					placeholder={t.forms.company_req}
					text={this.state.company}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="zip"
					label={t.forms.zip}
					placeholder={t.forms.zip_req}
					text={this.state.zip}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="email"
					label={t.forms.email}
					text={this.state.email}
					onChange={this.handleChange}
				/>
				<TableViewTextCell
					name="phone"
					label={t.forms.phone}
					placeholder={t.forms.phone_req}
					text={this.state.phone}
					onChange={this.handleChange}
				/>
				<TableViewMultilineCell
					name="message"
					label={t.forms.offer_message}
					text={this.state.message}
					onChange={this.handleChange}
				/>
				<p className="text" style={{ marginTop: "10px", marginLeft: "20px" }}>
					{t.formatString(t.forms.privacy_notice, {
						link: (
							<a href={PRIVACY_URL} target="_blank" rel="noreferrer">
								{t.forms.privacy_link}
							</a>
						),
					})}
				</p>
			</div>
			
		);
	}

	SuccessPopover() {
		return (
			<div className="successPopover">
				<p className="smallTitle" id="center" style={{ marginBottom: "30px" }}>
					Ihre Nachricht wurde verschickt.
				</p>

				<button onClick={this.goBack}>Fertig</button>
			</div>
		);
	}

	render() {
		const { t } = this.props.localizationHook;

		return (
			<div className="content">
				<NavBar
					title={t.forms.offer}
					backButtonTitle={
						this.props.showCancelButton ? null : t.general.back
					}
					leftBarButtonItemAction={
						this.props.showCancelButton ? this.props.cancelHandler : null
					}
					leftBarButtonItemTitle={
						this.props.showCancelButton
							? this.state.isSent
								? null
								: t.general.cancel
							: null
					}
					rightBarButtonItemTitle={
						this.state.isSent ? null : t.general.send
					}
					rightBarButtonIsConfirm={true}
					rightBarButtonItemAction={this.sendForm}
					disableRightBarButtonItem={
						this.state.firstname.length === 0 ||
						this.state.lastname.length === 0 ||
						this.state.email.length === 0 ||
						this.state.message.length === 0 ||
						this.state.isSending
					}
				/>

				{this.state.isSending ? (
					<LoadingView />
				) : this.state.isSent ? (
					this.SuccessPopover()
				) : (
					this.Form()
				)}
			</div>
		);
	}
}

export default withRouter(withAuth0(withLocalizationHook(OfferForm)));
