import { useMatrix } from "../useMatrix";
import "./MatrixGridItem.css";

function MatrixGridItem({ item }) {
	const { selectedClassesCount, changeSelection } = useMatrix()
	const { status, classId, selected } = item

	function idForStatus(status, selected) {
		if (selected) {
			return "selected";
		}

		switch (status) {
			case "1":
				return "stateGreen";
			case "2":
				return "stateYellow";
			case "3":
				return "stateRed";
			default:
				return null;
		}
	}

	/*
	function Remarks() {
		return (
			<div className="matrixGrid-item-warningsContainer">
				{props.item.remarks.length > 0 && props.item.status !== "3" && (
					<p className="matrixGrid-item-warning">
						{props.item.remarks.toString()}
					</p>
				)}
			</div>
		);
	}
*/
	function Content() {
		return (
			<div
				className="matrixGrid-item-content"
				id={idForStatus(status)}
			>
				<p className="matrixGrid-item-id">LGK</p>
				<p className="matrixGrid-item-id">{classId}</p>
			</div>
		);
	}

	return (
		<div
			className="matrixGrid-item"
			id={idForStatus(status, selected)}
			style={{
				opacity:
					selectedClassesCount > 0 && !item.selected ? "1" : "1",
				borderColor:
					selectedClassesCount > 0 ? "transparent" : "#a8a9ab",
			}}
			onClick={() => changeSelection({ classId: classId, selected: !selected })}
		>
			{Content()}
		</div>
	);
}

export default MatrixGridItem;
