export const MAX_FREE_USAGE = window._env_.REACT_APP_MAX_FREE_USAGE || 5;

export const CONSULTATION_URL =
	window._env_.REACT_APP_CONSULTATION_URL ||
	"https://www.denios.de/unternehmen/denios-services/vor-ort-beratung";
export const MATRIX_HELP_URL =
	window._env_.REACT_APP_MATRIX_HELP_URL || "hazard-classes";
export const PRIVACY_URL =
	window._env_.REACT_APP_PRIVACY_URL || "https://www.denios.de/denios-apps/datenschutz-apps";

export const AUTH0_BASE_URL =
	window._env_.REACT_APP_AUTH0_BASE_URL || "sitegeist.eu.auth0.com";
export const AUTH0_CLIENT_ID =
	window._env_.REACT_APP_AUTH0_CLIENT_ID || "rCB5577jdS0HhGLevXZLZvbx6vWKRWfg";
//export const AUTH0_REDIRECT_URI =
//window._env_.REACT_APP_AUTH0_REDIRECT_URI ||
//	"https://hazmat.denios.sitegeist.rocks";
export const AUTH0_REDIRECT_URI =
	window._env_.REACT_APP_AUTH0_REDIRECT_URI || "http://localhost:3000/loggedin";

export const STORYBLOK_TOKEN =
	window._env_.REACT_APP_STORYBLOK_TOKEN || "aT3NEBmQmTJy4cl6sTTuawtt";
export const STORYBLOK_BASE_URL =
	window._env_.REACT_APP_STORYBLOK_BASE_URL ||
	"https://api.storyblok.com/v1/cdn/stories/";

export const REACT_APP_MAIL_SERVICE_BASE_URL =
	window._env_.REACT_APP_MAIL_SERVICE_BASE_URL ||
	"mail-hazmat.dev.denios.io";
export const MAIL_SERVICE_DELETE_ACCOUNT_URL =
	window._env_.REACT_APP_MAIL_SERVICE_DELETE_ACCOUNT_URL ||
	"https://mail-hazmat.dev.denios.io/user";
export const MAIL_SERVICE_URL =
	window._env_.REACT_APP_MAIL_SERVICE_URL ||
	"https://mail-hazmat.dev.denios.io/send?appSecret=HfogTkrxFVPit3LLVevqmNLMHV7bEvLwaqt7eqtH";
export const VERSION_NUMBER =
	window._env_.VERSION_NUMBER ||
	"1.1.2";

// DEBUG OPTIONS
export const DISABLE_LIMITED_USAGE =
	window._env_.REACT_APP_DISABLE_LIMITED_USAGE || true;
export const SHOW_STORYBLOK_DEBUG_PAGE =
	window._env_.REACT_APP_SHOW_STORYBLOK_DEBUG_PAGE || false;
export const SHOW_MATRIX_INFO_BUTTON =
	window._env_.REACT_APP_SHOW_MATRIX_INFO_BUTTON || false;
export const HIDE_LANGUAGE_SELECT =
	window._env_.REACT_APP_HIDE_LANGUAGE_SELECT || false;

export const getNavigationRoute = 'navigations';
export const APP_BASE_PATH = 'apps/hazmat-storage/';
