import PropTypes from "prop-types";
import "./TableItem.css";

function TableItem({ label, onClick, selected }) {
	return (
		<div
			className="tableItemCell"
			onClick={onClick}>
			{label}
			<div className="tableItemCell-right">
				{selected &&<div className="tableItemCell-checkmark" />}
			</div>
		</div>
	);
}

TableItem.defaultProps = {
	label: "",
	onClick: () => {},
	selected: false,
};

TableItem.propTypes = {
	label: PropTypes.string.isRequired, // value for the cell label.
	onClick: PropTypes.func, // (optional) function that should be called when tapping or clicking the cell.
	selected: PropTypes.bool, // when disabled, the cell does not have a hover effect. Actions can still be called.
};

export default TableItem;
