import { MAIL_SERVICE_DELETE_ACCOUNT_URL } from "../data/Constants";
import { useAuth0 } from "@auth0/auth0-react";
import axios from "axios";
import ModalBox from "../Reusable Views/ModalBox/ModalBox";
import { useLocalization } from "src/Localization";

function DeleteAccountBox(props) {
	const { t } = useLocalization()
	const { getAccessTokenSilently, logout } = useAuth0();

	function closeBox() {
		props.closeFunction();
	}

	async function deleteAccount() {
		try {
			const accessToken = await getAccessTokenSilently({
				scope:
					"openid profile email delete:user read:current_user update:current_user_metadata",
				audience: "https://denios-hazmat-api/",
			});

			axios.defaults.headers.common.Accept = "application/json";
			axios.defaults.headers.common.Authorization = "Bearer " + accessToken;

			axios
				.delete(MAIL_SERVICE_DELETE_ACCOUNT_URL)
				.then(() => {
					logout({ returnTo: window.location.origin });
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (e) {
			console.log(e);
		}
	}

	function ModalContent() {
		return (
			<div>
				<p className="usageCountReminderBox-text">
					{t.delete_account.text}
				</p>

				<button onClick={deleteAccount}>
					{t.delete_account.confirmation_button}
				</button>
				<button className="outline" onClick={closeBox}>
					{t.general.cancel}
				</button>
			</div>
		);
	}

	return (
		<ModalBox
			title={t.delete_account.title}
			closeFunction={closeBox}
		>
			{ModalContent()}
		</ModalBox>
	);
}

export default DeleteAccountBox;
