import { useEffect, useState } from "react"
import { db } from "../../../data/db"
import LoadingView from "../../../Reusable Views/LoadingView"
import StoryblokRenderer from "../../../Reusable Views/StoryblokRenderer"

export const Explanation = ({ remark }) => {
  const [story, setStory] = useState(null)

  useEffect(() => {
    const loadRemark = async () => {
      setStory(await db.getContentByRoute(`/hazard-classes/explanations/${remark}`))
    }
    loadRemark()
  }, [remark])

  if (!story) return <LoadingView />
  return (
    <div>
      <p className="text2" style={{ marginTop: "15px" }}>
        Einschränkung {remark}:
      </p>

      <StoryblokRenderer content={story.content.contents} />
      <hr className="light" />
    </div>)
}