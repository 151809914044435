import slide_01 from './slide_01.gif';
import slide_02 from './slide_02.png';
import slide_03 from './slide_03.png';
import slide_04 from './slide_04.png';
import slide_05 from './slide_05.png';
import slide_06 from './slide_06.png';

export const Slides = [
    {
        key: 1,
        title: 'Lagerklasse auswählen',
        text: 'Wählen Sie die Lagerklassen aus, die Sie auf Zusammenlagerung prüfen möchten. Klicken Sie dazu auf die jeweiligen Felder der Lagerklassen.',
        image: slide_01,
        // backgroundColor: customColors.green1,
    },
    {
        key: 2,
        title: 'Ergebnis anzeigen',
        text: 'Mehr Infos zu Ihrer Zusammenlagerungsprüfung erhalten Sie unter „Ergebnis anzeigen“.',
        image: slide_02,
        // backgroundColor: '#febe29',
    },
    {
        key: 3,
        title: 'Detailansicht',
        text: 'Hier können Sie detaillierte Informationen zu Lagereinschränkungen der ausgewählten Kombinationen finden.\n\nUnter Legende LGK können Sie die jeweiligen Definitionen der Lagerklassen nachlesen.',
        image: slide_03,
        // backgroundColor: '#22bcb5',
    },
    {
        key: 4,
        title: 'Detailansicht',
        text: 'Zudem gibt es in der Detailansicht die Möglichkeit, ein Beratungsgespräch oder Angebot bei unseren Fachexperten anzufordern.',
        image: slide_04,
        // backgroundColor: '#22bcb5',
    },
    {
        key: 5,
        title: 'Profil bearbeiten',
        text: 'Unter "Einstellungen" können Sie Ihre persönlichen Daten anpassen oder sich ausloggen.',
        image: slide_05,
        // backgroundColor: '#22bcb5',
    },
    {
        key: 6,
        title: 'Anleitung später anschauen',
        text: 'Um die Anleitung später erneut anzuzeigen, tippen Sie unter "Infos & Hilfe" auf "Anleitung".',
        image: slide_06,
        // backgroundColor: '#22bcb5',
    },
];